import {useContext, useState,useEffect} from 'react';
import CartContext  from '../../store/cart-context';
import CartIcon from "../Cart/CartIcon";
import classes from './HeaderCartButton.module.css'
const HeaderCartButton = (props)=>{
    const [btnIsHighlighted, setBtnIsHighlighted] = useState(false);
    const cartCtx = useContext(CartContext);
    const numberOfCartItems = cartCtx.items.reduce((curNumber,item) => { return curNumber + item.amount;},0);
    
    const btnClasses = `${classes.button} ${btnIsHighlighted ? classes.bump :''}`;
    useEffect(() => {
        if(cartCtx.items.length === 0){
            return;
        }
        setBtnIsHighlighted(true);
        const timer = setTimeout(()=>{   
                 setBtnIsHighlighted(false);
        },300);
        return ()=> {clearTimeout(timer);}
    },[cartCtx]);
    return (
        <button className={btnClasses} onClick={props.onClick}>
            <span className={classes.icon}><CartIcon/></span>
            <span>Your cart</span>
            <span className={classes.badge}>{numberOfCartItems}</span>
        </button>
    );
};
export default HeaderCartButton;