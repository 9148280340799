import Card from '../UI/Card'
import classes from './AvailableMeals.module.css';
import MealItem from './MealItem/MealItem';
const Dummy_Meals = [ 
{
    id:'m1',
    name:'Tanthori',
    description:'Eat well',
    price:'16.2'
},
{
    id:'m2',
    name:'Grill',
    description:'Eat and crack',
    price:'22.1'
}
];

const AvailableMeals = () =>{
    const mealslist = Dummy_Meals.map((meal) => (
    <MealItem
     key={meal.id} 
     id={meal.id}
    name={meal.name} 
    description={meal.description} 
    price={meal.price}
    />));

return(
    <section className={classes.meals}>
    <Card>
    <ul>{mealslist}</ul>
    </Card>
    </section>
);
};
export default AvailableMeals;