import {Fragment} from 'react'
import mealsImage from '../../assets/meals.jpg';
import classes from './Header.module.css'
import HeaderCartButton from './HeaderCartButton';
const Header = props => {
    return(
    <Fragment>
     <header className={classes.header}>
      <h1>Enjoy Meals</h1>
      <HeaderCartButton onClick={props.onShowCart}/>
      {/* <button>cart</button> */}
      </header>
     <div className={classes['main-image']}>
      <img src={mealsImage} alt="The Meal of raj bhojan"/>
      </div>
    </Fragment>
    );
    
};
export default Header;
