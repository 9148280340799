import Input from '../../UI/Input';
import classes from './MealItemForm.module.css';
import {useState, useRef} from 'react';

const MealItemForm = (props) => {
  const [amountIsValid,setAmountIsValid]=useState(true);
  const amountInputRef = useRef();

  const submitHandler = (event)=>{
    event.preventDefault();
    const enterAmount = amountInputRef.current.value;
    const enteredAmountNumber = +enterAmount;

    if (
      enterAmount.trim().length === 0 ||
      enteredAmountNumber <1 ||
      enteredAmountNumber >5
    ){
      setAmountIsValid(false);
      return;
    }

  props.onAddToCart(enteredAmountNumber);
  };
  return (
    <form className={classes.form} onSubmit={submitHandler}>
       
      <Input
      ref ={amountInputRef}
        label='Amount'
        input={{
          id: 'amount_' + props.id,
          type: 'number',
          min: '1',
          max: '5',
          step: '1',
          defaultValue: '1',
        }}
      />
      <button>+ Add</button>
      {!amountIsValid && <p>Enter the valid Input</p>}
    </form>
    
  );
};

export default MealItemForm;